import { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import PhotoAlbum from "react-photo-album";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "react-photo-album/styles.css";

const ImageGallery = ({ images = [] }) => {
  const [index, setIndex] = useState(-1);
  const [galleryImage, setGalleryImages] = useState([]);

  useEffect(() => {
    if (images.length > 0) {
      const updatedImgs = images.map((img, i) => ({
        src: img,
        width: 620,
        height: 500,
        imageFit: "contain",
      }));
      setGalleryImages(updatedImgs);
    }
  }, [images]);

  return (
    <>
      <PhotoAlbum
        layout="masonry"
        photos={galleryImage}
        columns={(containerWidth) => {
          if (containerWidth < 380) return 2;
          if (containerWidth < 580) return 3;
          if (containerWidth < 900) return 4;
          return 4;
        }}
        onClick={({ index: current }) => setIndex(current)}
        render={{
          photo: ({ onClick }, { photo, width, height }) => (
            <div
              style={{
                width: `${width}px`,
                height: `${height}px`,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={onClick}
            >
              <img
                src={photo.src}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          ),
        }}
      />

      <Lightbox
        index={index}
        slides={galleryImage}
        plugins={[Zoom]}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
    </>
  );
};

export default ImageGallery;
