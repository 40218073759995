import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY
export const homepageApi = createApi({
    reducerPath: 'homepageApi',
    baseQuery: fetchBaseQuery({ baseUrl: API_URL}),
    endpoints: (builder) => ({
        getHomeData: builder.query({
            query: (lang) => ({
                url: "home-data",
                method: 'GET',
                headers: {
                    "api-key": API_KEY,
                    "X-localization": lang
                }
            })
        }),
        getHomeSectionTwo: builder.query({
            query: (lang) => ({
                url: "home-section-two",
                method: 'GET',
                headers: {
                    "api-key": API_KEY,
                    "X-localization": lang
                }
            })
        }),
        getHomeSectionThree: builder.query({
            query: (lang) => ({
                url: "home-section-three",
                method: 'GET',
                headers: {
                    "api-key": API_KEY,
                    "X-localization": lang
                }
            })
        })
    })
})

export const {useGetHomeDataQuery, useGetHomeSectionTwoQuery, useGetHomeSectionThreeQuery} = homepageApi