import React, { useEffect, useState } from "react";
import {
  Box,
  HeadingStyled,
  SubHeadingStyled,
} from "../../components/Styled/Elements";
import { useTranslation } from "react-i18next";
import { PackagesStyled } from "./styled";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { PagesBg } from "../../components/Styled/AllImages";
import { PackageCard } from "../../components/Styled/CustomElements";
import { useGetHomeDataQuery } from "../../store/services/homepageApi";

const OurPackages = () => {
  const { t, i18n } = useTranslation();
  const [packageList, setPackageList] = useState([])
  const lang = i18n.language;

  const {
    data: homeData,
    isError,
    isLoading,
    isFetching,
    error,
  } = useGetHomeDataQuery(i18n.language);

  useEffect(() => {
    if (homeData?.data?.packages.length > 0)
      setPackageList(homeData?.data?.packages);
  }, [homeData]);
  return (
    <>
      <PackagesStyled
        lang={lang}
        style={{ backgroundImage: `url(${PagesBg})` }}
      >
        <Container className="common-container">
          <Box>
            <HeadingStyled color="var(--white)" size="20px" weight="500">
              {t("achieve_your_best_with")}
            </HeadingStyled>
            <SubHeadingStyled size="36px" weight="600">
              {t("our_packages")}
            </SubHeadingStyled>
          </Box>
          <Box>
            {isLoading | isFetching ? (
				<Box className="d-flex justify-content-center">
				  <Spinner animation="border" className="custom-spinner" />
              </Box>
            ) : (
              <Row>
                {packageList.length > 0 && packageList.map((x, i) => (
                  <Col className="col-6">
                    <PackageCard data={x} />
                  </Col>
                ))}
              </Row>
            )}
          </Box>
        </Container>
      </PackagesStyled>
    </>
  );
};

export default OurPackages;
