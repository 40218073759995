import React, { useEffect, useState } from "react";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import {
  ArrowRight,
  Check,
  GoldWhey1,
  GoldWhey2,
  GoldWhey3,
  GoldWhey4,
  PagesBg,
} from "../../components/Styled/AllImages";
import {
  Box,
  HeadingStyled,
  TextStyled,
} from "../../components/Styled/Elements";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ProductDetailsStyled } from "./styled";
import {
  ContactButton,
  NumberInput,
  ProductCard,
} from "../../components/Styled/CustomElements";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetProductDetailsQuery } from "../../store/services/productsApi";
import { useGetHomeDataQuery } from "../../store/services/homepageApi";

const ProductDetails = () => {
  const PRODUCTS_ASSET_URL = process.env.REACT_APP_PRODUCTS_ASSET_URL;
  const { id } = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);
  const [productDetails, setProductDetails] = useState({});
  const [images, setImages] = useState([]);

  const { data: homeData } = useGetHomeDataQuery(language);

  const {
    data: productData,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetProductDetailsQuery({ id, lang: language });
  // const [quantity, setQuantity] = useState(0);

  // const images = [GoldWhey1, GoldWhey2, GoldWhey3, GoldWhey4, GoldWhey2];

  const handleClick = (id) => {
    navigate(`/product-details/${id}?product=${productDetails?.name}`);
  };

  useEffect(() => {
    if (homeData?.data?.products.length > 0 && productDetails) {
      const products = homeData?.data?.products;
      setProductList(
        products.filter((product) => product.id != productDetails.id)
      );
    }
  }, [homeData, productDetails]);
  useEffect(() => {
    setProductDetails(productData?.data);
    if(productData?.data?.images && productData?.data?.images.length > 0){
      setImages(productData?.data?.images.map(img => `${PRODUCTS_ASSET_URL}/${img.more_image}`))
    }
  }, [productData]);

  useEffect(() => {
    refetch();
  }, [refetch, language]);

  return (
    <>
      <ProductDetailsStyled style={{ backgroundImage: `url(${PagesBg})` }}>
        <Container className="common-container">
          {isLoading | isFetching ? (
            <Box className="d-flex justify-content-center">
              <Spinner animation="border" className="custom-spinner" />
            </Box>
          ) : (
            <>
              <Row className="product-dtl-section">
                <Col lg={6} md={12}>
                  <ImageSlider images={images} />
                </Col>
                <Col lg={6} md={12}>
                  <Box>
                    <HeadingStyled size="36px" weight="600">
                      {productDetails?.name}
                    </HeadingStyled>
                  </Box>
                  <Box className="d-flex mt-3">
                    <Box>
                      <TextStyled size="16px" className="mb-1" weight="500">
                        {t("size")}:
                      </TextStyled>
                    </Box>
                    <Box>
                      <TextStyled
                        size="16px"
                        color="var(--white)"
                        weight="400"
                        className="mb-1 mx-3"
                      >
                        {productDetails?.size}
                      </TextStyled>
                    </Box>
                  </Box>
                  <Box className="d-flex mt-3">
                    <Box>
                      <TextStyled size="16px" className="mb-1" weight="500">
                        {t("flavor")}:
                      </TextStyled>
                    </Box>
                    <Box>
                      <TextStyled
                        size="16px"
                        color="var(--white)"
                        weight="400"
                        className="mb-1 mx-3"
                      >
                        {productDetails?.flavour}
                      </TextStyled>
                    </Box>
                  </Box>
                  <Box className="pt-3">
                    <HeadingStyled size="24px" weight="500">
                      {t("description")}
                    </HeadingStyled>
                    {productDetails?.description && (
                        <Box className="d-flex">
                          <TextStyled size="16px" weight="300" className="mx-3">
                            {productDetails?.description}
                          </TextStyled>
                        </Box>
                      )}
                  </Box>
                  <Box className="py-3 key-points">
                    <HeadingStyled size="24px" weight="500">
                      {t("features")}
                    </HeadingStyled>
                    {productDetails?.features &&
                      productDetails?.features.split("|").length > 0 &&
                      productDetails?.features.split("|").map((list, key) => (
                        <Box className="d-flex" key={key}>
                          <Check className="mt-1" />
                          <TextStyled size="16px" weight="300" className="mx-3">
                            {t(list)}
                          </TextStyled>
                        </Box>
                      ))}
                  </Box>
                  <Box className="mt-4">
                    <HeadingStyled size="32px" weight="500">
                      {productDetails?.price && productDetails.price} {t("sar")}
                    </HeadingStyled>
                    <Box className="mt-4">
                      <ContactButton
                        label={
                          productDetails && parseInt(productDetails.qty) < 1
                            ? t("out_of_stock")
                            : t("continue_checkout")
                        }
                        icon={ArrowRight}
                        clName={"pt-0 checkout-btn"}
                        onClick={() => navigate(`/checkout?productId=${id}`)}
                        disabled={
                          productDetails && parseInt(productDetails?.qty) < 1
                        }
                      />
                    </Box>
                  </Box>
                </Col>
              </Row>
              {productList.length > 0 && (
                <Box className="mt-5">
                  <HeadingStyled size="36px" className="mb-4" weight="500">
                    {t("you_may_also_like")}
                  </HeadingStyled>
                  <Row>
                    {productList.slice(0, 4).map((x, i) => (
                      <Col lg={3} md={6} sm={12}>
                        <ProductCard data={x} t={t} handleClick={handleClick} />
                      </Col>
                    ))}
                  </Row>
                </Box>
              )}
            </>
          )}
        </Container>
      </ProductDetailsStyled>
    </>
  );
};

export default ProductDetails;
