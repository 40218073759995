import React from "react";
import { ProductsStyled } from "./styled";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Box, HeadingStyled, SubHeadingStyled } from "../../Styled/Elements";
import { products } from "../../data/Data";
import { ContactButton, ProductCard } from "../../Styled/CustomElements";
import MoveOn from "./MoveOn";
import { ArrowRight } from "../../Styled/AllImages";

const Products = ({data}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (id) => {
    const item = data.length > 0 && data.find(x => x.id == id)
    navigate(`/product-details/${id}?product=${item.name}`)
  }
  return (
    <>
      <ProductsStyled className="container-fluid common-space" id="products">
        <Container className="products-container">
          <Box>
            <HeadingStyled color="var(--white)" size="20px" weight="500">
              {t("explore_premium_selection")}
            </HeadingStyled>
            <SubHeadingStyled size="36px" weight="600">
              {t("our_products")}
            </SubHeadingStyled>
          </Box>
          <Box className="mt-4">
            <Row>
              {data && data.length > 0 && data.slice(0, 8).map((x, i) => (
                <Col lg={3} md={6} sm={6} className="mt-4 col-6">
                  <ProductCard data={x} t={t} handleClick={handleClick} />
                </Col>
              ))}
            </Row>
          </Box>

          <Row>
            <Col className="d-flex justify-content-center mt-5 mb-5">
              <ContactButton
                size="14px"
                icon={ArrowRight}
                label={t("ViewAllProducts")}
                onClick={() => navigate("/products")}
              />
            </Col>
          </Row>
        </Container>
        <MoveOn />
      </ProductsStyled>
    </>
  );
};

export default Products;
