import styled from "styled-components";

export const PackagesStyled = styled.div`
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .pkg-content {
    z-index: 9;
  }
  .duration-text {
    display: inline-block;
    background-color: rgba(38, 205, 159, 0.3);
  }
  .bg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid var(--secBorderColor);
    transform: ${(props) => (props.lang === "ar" ? "scaleX(-1)" : "scaleX(1)")};
  }

`;
export const PackageDetailStyled = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
.input-field::placeholder {
  font-weight: 100;
  font-family: ${(props) =>
    props.lang === "en" ? "var(--regular)" : "var(--regularAr)"};
  color: var(--textPalceholder);
}
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield !important;
  }
  .pkg-detail_box{
    border-bottom: 1px solid var(--secBorderColor);
  }
  .package-img img {
    height: 511px;
    object-fit: cover;
    object-position: right;
  }
  .experience-container {
  }
  .duration-text {
    display: inline-block;
    background-color: rgba(38, 205, 159, 0.3);
  }

  @media (max-width: 575px) {
   .recommended-header {
    font-size: 28px!important;
   }
  }
`;
