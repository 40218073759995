import React from 'react'
import styled from 'styled-components'
import { Col, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, LazyImage, MainHeading, SubHeading, TextStyled } from '../../Styled/Elements'
import { AboutBg, AboutImg, AboutImg1, AboutImg2, AboutImg3, AboutImgAr, ArrowRight, Stripe, StripeAr } from '../../Styled/AllImages'
import { ContactButton } from '../../Styled/CustomElements'
import { AboutWrapper } from './Styled'
import Expertise from './Expertise'
import { useNavigate } from 'react-router-dom'

const About = ({data}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    /* const WhatsApp = () => {
        const phoneNumber = '';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    }; */
    return (
        <>
          
            <Expertise data={data} />
        </>
    )
}

export default About
