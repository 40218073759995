import styled from "styled-components";

export const ReviewsStyled = styled.div`
  padding: 40px 20px;
  text-align: center;
  color: #fff;
  border-radius: 20px;

  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 700px !important;
    height: 400px; */
    padding: 20px;
    border: 1px solid #2c3e50;
    background: radial-gradient(
        54.48% 58.56% at 57.57% 43.76%,
        #114244 0%,
        #12233d 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border: 1px solid var(--secBorderColor);
  }
  .review-card {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .review-author {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 5px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }

    .author-name {
      font-size: 18px;
      color: #fff;
    }
  }
  @media (max-width: 730px) {
    .quote-icon svg{
      width: 30px;
    }
    .review-text {
      font-size: 16px;
    }
    .review-author {
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  @media (max-width: 575px) {
    .review-desc {
      display: none;
    }
    .review-text {
      font-size: 10px;
      line-height: 1.2;
    }
    .review-card {
      height: 300px;
    }
    .swiper-slide {
    padding: 10px;
  }
  }
`;
