import React, { useEffect, useState } from "react";
import { CheckoutFormWrapper } from "./styled";
import { useForm } from "react-hook-form";
import { Box, LabelStyled } from "../../components/Styled/Elements";
import { ContactButton } from "../../components/Styled/CustomElements";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ArrowRight2 } from "../../components/Styled/AllImages";
import { useGetProductDetailsQuery } from "../../store/services/productsApi";
import { useCreateSessionMutation } from "../../store/services/checkoutApi";
const CheckoutForm = ({ stateData, packageId, productId, amountSummary }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const formObj = {
    customer_name: "",
    age: "",
    email: "",
    phone_number: "",
    address: "",
    city: "",
    // state: "",
    // zip_code: "",
  };
  const [formData, setFormData] = useState(formObj);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });

  const [
    trigger,
    {
      data: checkoutData,
      isLoading: isCheckoutLoading,
      isFetching: isCheckoutFetching,
      isError: isCheckoutError,
      refetch: refetchCheckout,
    },
  ] = useCreateSessionMutation();

  // const {
  //   data: productData,
  //   isLoading,
  //   isFetching,
  //   isError,
  //   error,
  //   refetch
  // } = useGetProductDetailsQuery({ productId, lang: language });

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValue(name, value);
  };
  const onSubmit = async () => {
    const orderData = {
      ...formData,
      ...amountSummary,
      product_id: productId,
      order_type: packageId ? 3 : 2,
      ...(packageId && { package_id: packageId }),
    };
    try {
      const { data, error } = await trigger({
        lang: language,
        data: orderData,
      });

      if (error) {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      } else if (data) {
        localStorage.setItem(
          "order_info",
          JSON.stringify({
            telr_order_reference: data?.data?.telr_response?.ref,
            order_id: data?.data?.order.order_id,
          })
        );

        const checkoutUrl = data?.data?.telr_response?.url;
        if (checkoutUrl) {
          localStorage.setItem("order_data", JSON.stringify(orderData));
          window.location.href = checkoutUrl;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (stateData) {
      setFormData((prevData) => ({
        ...prevData,
        ...stateData,
      }));
      reset({
        ...formData,
        ...stateData,
      });
    }
  }, [stateData, reset]);

  return (
    <>
      <CheckoutFormWrapper lang={language}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="field-box">
            <LabelStyled color="var(--themeColor)" weight="lighter">
              {t("full_name")}
            </LabelStyled>
            <input
              className="input-field"
              placeholder={t("fullNamePlaceholder")}
              value={formData.customer_name}
              name="customer_name"
              {...register("customer_name", {
                required: true,
                validate: (value) => {
                  const nameParts = value.trim().split(" ").filter(Boolean);
                  return nameParts.length >= 2 || "invalid_name_error";
                },
                onChange: onChange,
              })}
            />
            {errors.customer_name && (
              <p className="error-message" role="alert">
                {t(errors.customer_name.message) || t("full_name_error")}
              </p>
            )}
          </Box>
          {packageId && (
            <Box className="field-box">
              <LabelStyled color="var(--themeColor)" weight="lighter">
                {t("age")}
              </LabelStyled>
              <input
                className="input-field"
                name="age"
                placeholder={t("agePlaceholder")}
                type="text"
                value={formData.age}
                {...register("age", {
                  required: true,
                  onChange: onChange,
                })}
              />
              {errors.age && (
                <p className="error-message" role="alert">
                  {t("age_error")}
                </p>
              )}
            </Box>
          )}
          <Box className="field-box">
            <LabelStyled color="var(--themeColor)" weight="lighter">
              {t("email")}
            </LabelStyled>
            <input
              className="input-field"
              placeholder={t("emailPlaceholder")}
              type="email"
              value={formData.email}
              name="email"
              {...register("email", {
                required: true,
                onChange: onChange,
              })}
            />
            {errors.email && (
              <p className="error-message" role="alert">
                {t("email_error")}
              </p>
            )}
          </Box>
          <Box className="field-box">
            <LabelStyled color="var(--themeColor)" weight="lighter">
              {t("phone")}
            </LabelStyled>
            <input
              className="input-field"
              name="phone_number"
              placeholder={t("phonePlaceholder")}
              type="text"
              value={formData.phone_number}
              {...register("phone_number", {
                required: true,
                onChange: onChange,
              })}
            />
            {errors.phone_number && (
              <p className="error-message" role="alert">
                {t("phone_error")}
              </p>
            )}
          </Box>
          <Box className="field-box">
            <LabelStyled color="var(--themeColor)" weight="lighter">
              {t("address")}
            </LabelStyled>
            <input
              className="input-field"
              name="address"
              placeholder={t("addressPlaceholder")}
              type="text"
              value={formData.address}
              {...register("address", {
                required: true,
                onChange: onChange,
              })}
            />
            {errors.address && (
              <p className="error-message" role="alert">
                {t("address_error")}
              </p>
            )}
          </Box>
          <Box className="field-box">
            <LabelStyled color="var(--themeColor)" weight="lighter">
              {t("city")}
            </LabelStyled>
            <input
              className="input-field"
              name="city"
              placeholder={t("cityPlaceholder")}
              type="text"
              value={formData.city}
              {...register("city", {
                required: true,
                onChange: onChange,
              })}
            />
            {errors.city && (
              <p className="error-message" role="alert">
                {t("city_error")}
              </p>
            )}
          </Box>
          {/* <Box className="field-box">
            <LabelStyled color="var(--themeColor)" weight="lighter">
              {t("state")}
            </LabelStyled>
            <input
              className="input-field"
              name="state"
              placeholder={t("statePlaceholder")}
              type="text"
              value={formData.state}
              {...register("state", {
                required: true,
                onChange: onChange,
              })}
            />
            {errors.state && (
              <p className="error-message" role="alert">
                {t("state_error")}
              </p>
            )}
          </Box> */}
          {/* <Box className="field-box">
            <LabelStyled color="var(--themeColor)" weight="lighter">
              {t("zip_code")}
            </LabelStyled>
            <input
              className="input-field"
              name="zip_code"
              placeholder={t("zipCodePlaceholder")}
              type="text"
              value={formData.zip_code}
              {...register("zip_code", {
                required: true,
                onChange: onChange,
              })}
            />
            {errors.zip_code && (
              <p className="error-message" role="alert">
                {t("zipCode_error")}
              </p>
            )}
          </Box> */}
          <Box className="d-flex justify-content-end my-3 mx-2 checkout-btn">
            <ContactButton
              type="submit"
              label={t("continue_to_payment")}
              icon={ArrowRight2}
              isLoading={isCheckoutLoading}
              disabled={isCheckoutLoading}
            />
          </Box>
        </form>
      </CheckoutFormWrapper>
    </>
  );
};

export default CheckoutForm;
