import styled from "styled-components";

export const BannerWrapper = styled.section`
  .bannerContentWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100vh;
    min-height: 700px;
    .banner-content {
      width: 970px;
      /* position: relative; */
      .bannerMan {
        position: absolute;
        z-index: -1;
        right: 0;
        left: 0;
        bottom: -40px;
        margin: auto;
        display: none;
      }
      .bannerManEn {
        display: block;
      }
    }
    .bannerHeading {
      font-family: var(--medium);
    }
    .caption-sub-heading {
      font-size: 40px;
    }
    .caption-snd-heading {
      margin-bottom: 40px;
    }
    .caption-heading-main,
    .caption-snd-heading {
      font-size: 65px;
    }
  }
  .text-styled {
    position: relative;
    right: 15px;
    top: 10px;
  }
  .down-btn {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;
    width: 90px;
    bottom: -10px;
  }
  @media (max-width: 991px) {
    .bannerContentWrapper {
      height: auto;
      min-height: auto;
      padding-top: 120px;
      padding-bottom: 100px;
      .caption-sub-heading {
        font-size: 35px;
      }
      .caption-snd-heading {
        margin-bottom: 10px;
        font-size: 50px;
      }
      .caption-heading-main {
        font-size: 50px;
        line-height: 1.3;
      }
      .banner-content .bannerMan {
        width: 430px;
        bottom: -10px;
      }
    }
    .contactWrapper {
      button {
        gap: 8px;
        height: 46px;
        padding: 0px 10px;
        span {
          font-size: 16px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .bannerContentWrapper {
      .banner-content .bannerMan {
        width: 380px;
      }
      .caption-sub-heading {
        font-size: 30px;
      }
      .caption-snd-heading {
        font-size: 40px;
      }
      .caption-heading-main {
        font-size: 40px;
      }
    }
  }
  @media (max-width: 575px) {
    .bannerContentWrapper {
      padding-top: 110px;
      padding-bottom: 50px;
      .banner-content .bannerMan {
        width: 330px;
        left: auto;
      }
    }
  }
  @media (max-width: 380px) {
    .bannerContentWrapper {
      .banner-content .bannerMan {
        width: 300px;
        bottom: 0px;
        right: -32px;
      }
    }
  }
`;

export const AboutWrapper = styled.section`
  margin-bottom: 2.5rem;
  img.aboutBg {
    mix-blend-mode: soft-light;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .aboutContentCol {
    padding-right: 90px;
    padding-left: 30px;
  }
  .aboutStripe {
    position: absolute;
    bottom: -170px;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
    display: none;
  }
  .aboutStripeEn {
    display: block;
  }
  .aboutImgCol {
    display: flex;
    gap: 15px;
    .aboutImgContainer {
      position: relative;
      span {
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, 0.2);
      }
      .aboutImgTxt {
        position: absolute;
        bottom: 20px;
        left: 10px;
        right: 10px;
        text-align: center;
        z-index: 1;
        color: var(--white);
        font-size: 18px;
        line-height: 25px;
        font-family: var(--lightMedium);
        text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.35);
        -webkit-text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.35);
        -moz-text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.35);
      }
    }
  }
  @media (max-width: 1399px) {
    .aboutContentCol {
      padding-right: 40px;
    }
  }
  @media (max-width: 1199px) {
    .aboutContentCol {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
    }
    .aboutImgCol {
      display: flex;
      gap: 5px;
    }
    .main-heading {
      margin-bottom: 10px;
    }
    .commonTxt {
      margin-bottom: 0;
    }
  }
  @media (max-width: 991px) {
    padding-top: 60px;
    padding-bottom: 120px;
    .aboutImgCol {
      margin-bottom: 70px;
      justify-content: center;
      padding-right: 2rem;
      padding-left: 2rem;
    }
    .aboutStripe {
      max-width: 3500px;
      bottom: -120px;
    }
    .aboutContentCol {
      padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
      padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    }
  }
  @media (max-width: 575px) {
    .aboutImgCol .aboutImgContainer .aboutImgTxt {
      left: 3px;
      right: 3px;
      font-size: 15px;
      line-height: 20px;
    }
    .aboutContentCol {
      display: none;
    }
  }
  @media (max-width: 360px) {
    .aboutImgCol {
      /* display: none; */
    }
  }
`;
