import About from "../components/sections/about/About";
import Banner from "../components/sections/banner/Banner";
import Header from "../components/header/Header";
import Packages from "../components/sections/packages/Packages";
import Trainers from "../components/sections/trainers/Trainers";
import Faqs from "../components/sections/faqs/Faqs";
import Footer from "../components/footer/Footer";
import { useEffect, useState } from "react";
import Products from "../components/sections/products/Products";
import { useGetHomeDataQuery, useGetHomeSectionThreeQuery, useGetHomeSectionTwoQuery } from "../store/services/homepageApi";
import { useTranslation } from "react-i18next";
import Reviews from "../components/sections/reviews/Reviews";

const Index = ({ removeQuery }) => {
  const { i18n } = useTranslation();
  const [productList, setProductList] = useState([])
  const [packageList, setPackageList] = useState([])
  const [trainerList, setTrainerList] = useState([])
  const [reviewsList, setReviewsList] = useState([])
  const [videosList, setVideosList] = useState([])
  const {
    data: homeData,
    isError,
    isLoading,
    isFetching,
    error,
  } = useGetHomeDataQuery(i18n.language);
  const {
    data: homeTwoData,
    isError: isHomeTwoError,
    isLoading: isHomeTwoLoading,
    isFetching: isHomeTwoFetching,
    error: homeTwoError,
  } = useGetHomeSectionTwoQuery(i18n.language);
  const {
    data: homeThreeData,
    isError: isHomeThreeError,
    isLoading: isHomeThreeLoading,
    isFetching: isHomeThreeFetching,
    error: homeThreeError,
  } = useGetHomeSectionThreeQuery(i18n.language);

  useEffect(() => {
    if(homeData?.data?.products.length > 0){
      const products = homeData?.data?.products
      setProductList(products.filter(product => product.is_featured == 1))
    }
    if(homeData?.data?.packages.length > 0){
      const packages = homeData?.data?.packages
      setPackageList(packages.filter(pkg => pkg.is_featured == 1))
    }
    if(homeTwoData?.data?.trainers.length > 0){
      const trainers = homeTwoData?.data?.trainers
      setTrainerList(trainers.filter(trainer => trainer.is_featured == 1))
    }
  }, [homeData, homeTwoData])
  useEffect(() => {
    if(homeThreeData?.data?.reviews_section.length > 0){
      const reviews = homeThreeData?.data?.reviews_section
      setReviewsList(reviews)
    }
    if(homeThreeData?.data?.videos_section.length > 0){
      const videos = homeThreeData?.data?.videos_section
      setVideosList(videos)
    }
  }, [homeThreeData])

  return (
    <>
      <Header removeParam={removeQuery} />
      <Banner videoData={videosList} />
      <Reviews data={reviewsList} />
      <Packages data={packageList} />
      <Products data={productList} />
      <Trainers data={trainerList} />
      <About data={homeData?.data?.testimonials} />
      <Faqs data={homeTwoData?.data?.faqs} />
      <Footer packages={packageList}/>
    </>
  );
};

export default Index;
