import styled from "styled-components";

export const ExpertiseWrapper = styled.section`
    z-index: 18;
    padding-bottom: 70px;
    .expertiseBg{
        mix-blend-mode: soft-light;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .sliderWrapper{
        margin-top: 10px;
    }
    .slide-item{
        display: flex;
        border: 1px solid #136750;
        padding: 25px;
        gap: 10px;
        align-items: center;
        background: var(--darkGradient);
    }
    
    .slick-slide > div{
        margin: 0 6px;
    }
    .slideTitle{
        display: flex;
        font-family: var(--medium);
        font-size: 22px;
        color:  var(--white);
        line-height: normal;
        margin-bottom: 0;
    }
    .slide-item .contactWrapper{
        button{
            height: 44px;
            font-family: var(--regular);
        }
    }
    .afImg {
        width: 300px;
        height: 341px;
        object-fit: contain;
    }
        
    @media (max-width: 1399px) {
        .slide-item {
            padding: 15px !important;
        }
        /* .afImg {
            min-width: 250px;
            max-width: 250px;
        } */
    }
    @media (max-width: 1199px) {
        .afImg {
            min-width: 220px;
            max-width: 220px;
        }
    }
    @media (max-width: 991px) {
        .slide-item {
            justify-content: space-between;
        }
        .expertiseRow {
            padding-top: 30px;
        }
    }
    @media (max-width: 767px) {
        .expertiseRow {
            padding-top: 10px;
        }
    }
    @media (max-width: 575px) {
        .slide-item {
            flex-wrap: wrap;
            justify-content: center;
            .afImg{
                margin-top: 15px;
            }
        }
        .slide-text{
            display: flex;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            p{
                text-align: center;
                justify-content: center;
            }
        }
    }
    
    

`
