import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import styled from "styled-components";
import { Box, LazyImage } from "../../../Styled/Elements";

const VIDEOS_ASSET_URL = process.env.REACT_APP_VIDEOS_ASSET_URL;

const VideoSlider = ({ videos }) => {
  const { t } = useTranslation();
  const [showVideo, setShowVideo] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState("");
  const [isIframeReady, setIsIframeReady] = useState(false);

  const extractVideoId = (url) => {
    const match = url.match(/\/video\/(\d+)/);
    return match ? match[1] : null;
  };

  const handleSlideClick = (videoUrl) => {
    const videoId = extractVideoId(videoUrl);
    if (videoId) {
      setCurrentVideoId(videoId);
      setIsIframeReady(false);
      setShowVideo(true);
    } else {
      console.error("Invalid TikTok URL");
    }
  };

  const handleIframeLoad = () => {
    setIsIframeReady(true);
  };

  const handleFullscreenClose = () => {
    setCurrentVideoId("");
    setShowVideo(false);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    draggable: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // responsive: [
    //   {
    //     breakpoint: 576,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  return (
    <>
      <SliderWrapper>
        <Slider {...settings}>
          {videos.map((video) => (
            <SlideContent
              key={video.id}
              onClick={() => handleSlideClick(video.link)}
            >
              <LazyImage
                src={`${VIDEOS_ASSET_URL}/${video.thumbnail}`}
                alt={`Thumbnail ${video.id}`}
              />
              <Box className="overlay">{t("play_video")}</Box>
            </SlideContent>
          ))}
        </Slider>
      </SliderWrapper>

      <FullScreenVideo visible={showVideo}>
        <Box className="close-btn" onClick={handleFullscreenClose}>
          ✖
        </Box>
        {currentVideoId && (
          <iframe
            src={`https://www.tiktok.com/embed/${currentVideoId}`}
            allow="autoplay; fullscreen"
            onLoad={handleIframeLoad}
            style={{ display: isIframeReady ? "block" : "none" }}
          ></iframe>
        )}
        {!isIframeReady && <div style={{ color: "white" }}>{t("loading")}</div>}
      </FullScreenVideo>
    </>
  );
};

export default VideoSlider;

const SliderWrapper = styled.div`
  width: 100%;
  .slick-slide {
    /* padding: 10px; */
  }
  .slick-track {
    display: flex;
    gap: 10px;
  }
  .slick-slide > div {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 575px) {
    .slick-track {
      gap: 5px;
    }
  }
`;

const SlideContent = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 350px;
  span {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  &:hover .overlay {
    opacity: 1;
  }
  @media (max-width: 767px) {
    height: 280px;
  }
  @media (max-width: 575px) {
    height: 200px;
  }
`;

const FullScreenVideo = styled.div`
  position: fixed;
  top: 65px;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: ${(props) => (props.visible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 999;
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  iframe {
    width: 90%;
    height: 80%;
    border: none;
  }
`;
