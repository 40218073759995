import React, { useEffect, useRef, useState } from "react";
import { ReviewsStyled } from "./styled";
import { Box, HeadingStyled, TextStyled } from "../../Styled/Elements";
import { useTranslation } from "react-i18next";
import { ProfilePlaceholder, ReviewIcon } from "../../Styled/AllImages";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import AudioPlayer from "./components/AudioPlayer";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const Reviews = ({ data }) => {
  const REVIEWS_ASSETS_URL = process.env.REACT_APP_REVIEWS_ASSET_URL;

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [sliderKey, setSliderKey] = useState(null);

  const audioRefs = useRef([]);
  const handleSlideChange = () => {
    audioRefs.current.forEach((audioRef) => {
      if (audioRef) {
        audioRef.pauseAudio();
      }
    });
  };

  useEffect(() => {
    setSliderKey(language);
  }, [language]);

  return (
    <ReviewsStyled>
      <HeadingStyled size="36px" weight="bold" color="var(--themeColor)" className="my-2">
        {t("our_customer_reviews")}
      </HeadingStyled>
      <TextStyled
        size="20px"
        color="var(--text)"
        className="justify-content-center review-desc"
      >
        {t("learn_about_our_customer_experience")}
      </TextStyled>
      <Box className="slider-container">
        {data.length > 0 && (
          <Swiper
            key={sliderKey}
            spaceBetween={30}
            grabCursor={true}
            loop={true}
            centeredSlides={true}
            slidesPerView={2}
            autoplay={{
              delay: 4000,
              pauseOnMouseEnter: true
            }}
            pagination={{ clickable: true }}
            dir={language == "ar" ? "rtl" : "ltr"}
            // slidesPerView="auto"
            effect={"coverflow"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 200,
              modifier: 1,
              slideShadows: true,
            }}
            breakpoints={{
              640: {
                centeredSlides: true
              },
              0: {
                centeredSlides: false,
                spaceBetween: 10
              }
            }}
            modules={[EffectCoverflow, Pagination, Autoplay]}
            onSlideChange={handleSlideChange}
            className="mySwiper"
          >
            {data.map((review, index) => (
              <SwiperSlide key={index}>
                <Box className="review-card">
                  <Box className="quote-icon">
                    <ReviewIcon />
                  </Box>
                  {review.review_type == 1 ? (
                    <TextStyled size="20px" color="var(--text)" className="justify-content-center review-text">
                      {review.text}
                    </TextStyled>
                  ) : (
                    <AudioPlayer
                    ref={(el) => (audioRefs.current[index] = el)}
                      audioSrc={`${REVIEWS_ASSETS_URL}/${review.voice}`}
                    />
                  )}
                  <Box className="review-author">
                    <img
                      src={review.customer_image ? `${REVIEWS_ASSETS_URL}/${review.customer_image}`: ProfilePlaceholder}
                      alt={review.customer_name}
                    />
                    <h4>{review.customer_name}</h4>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Box>
    </ReviewsStyled>
  );
};

export default Reviews;
